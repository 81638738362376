import React from 'react';
import SectionContainer from '../SectionContainer';
import styles from './SectionCategory.module.css';
import mobileHero from './mobileHero.png'; 
import logo from './logo.png'; 

const SectionCategory = ({ sectionId, title, categories = [], options }) => {
  const navigateToShopDeals = () => {
    window.location.href = '/s';
  };

  const navigateToListNow = () => {
    window.location.href = '/l/new';
  };

  return (
    <SectionContainer
      id={sectionId}
      options={options}
      className={styles.sectionCategory}
      style={{
        backgroundColor: '#fff',
      }}
    >
      {/* Desktop Banner */}
      <div className={styles.desktopHeroBanner}>
        <div className={styles.desktopHeroTextContainer}>
          <img src={logo} alt="logo" className={styles.desktopHeroLogo} />
          <h3 className={styles.desktopHeroText}>
            Shop & Sell.<br />
            Discover unique finds.
          </h3>
          <div className={styles.desktopHeroButtons}>
            <button className={styles.heroButton} onClick={navigateToShopDeals}>Shop deals</button>
            <button className={styles.heroButton} onClick={navigateToListNow}>List now</button>
          </div>
        </div>
        <img src={mobileHero} alt="Desktop Hero" className={styles.desktopHeroImage} />
      </div>

      {/* Categories */}
      <div className={styles.categories}>
        {categories.map((category, index) => (
          <div key={index} className={styles.categoryContainer} onClick={() => (window.location.href = category.url)}>
            <img src={category.image} alt={category.name} className={styles.categoryImage} />
            <div>
              <h3>{category.name}</h3>
            </div>
          </div>
        ))}
      </div>

      {/* Mobile Banner */}
      <div className={styles.mobileHeroBanner}>
        <div className={styles.mobileHeroTextContainer}>
          <h3 className={styles.mobileHeroText}>Shop & Sell.</h3>
          <h3 className={styles.mobileHeroText}>Discover unique</h3>
          <h3 className={styles.mobileHeroText}>finds.</h3>
          <div className={styles.mobileHeroButtons}>
            <button className={styles.heroButton} onClick={navigateToShopDeals}>Shop deals</button>
            <button className={styles.heroButton} onClick={navigateToListNow}>List now</button>
          </div>
        </div>
        <img src={mobileHero} alt="Mobile Hero" className={styles.mobileHeroImage} />
      </div>
    </SectionContainer>
  );
};

export default SectionCategory;
