import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IconSpinner, LayoutComposer, ListingCard } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';
import FooterContainer from '../FooterContainer/FooterContainer.js';
import bannerImage from '../../assets/MinichrBanner.png';
import logo from '../../assets/logo.jpeg';
import figurine from '../../assets/figurineIcon.png';
import art from '../../assets/artIcon.png';
import handcrafted from '../../assets/handcraftedIcon.png';
import apparel from '../../assets/apparelIcon.png';
import manga from '../../assets/mangaIcon.png';
import accessories from '../../assets/accessoriesIcon.png';

import { validProps } from './Field';

import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';
import { fetchProductsByIds, fetchFeaturedItems } from '../ListingPage/ListingPage.duck';
import css from './PageBuilder.module.css';
import profileData from './profiledata.json'

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */

const PageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    listing,
    currentPage,
    ...pageProps
  } = props;

  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }
  const dispatch = useDispatch();
  const [localListings, setLocalListings] = useState([]);
  const [featuredItems, setFeaturedItems] = useState([]);
  const [homepage, setHomepage] = useState('');

  useEffect(() => {
    const productIDsJSON = localStorage.getItem('recentlyViewedListings');

    if (productIDsJSON) {
      const productIDsArray = JSON.parse(productIDsJSON);
      if (Array.isArray(productIDsArray) && productIDsArray.length > 0) {
        dispatch(fetchProductsByIds(productIDsArray)).then(response => {
          setLocalListings(response);
        });
      } else {
        console.log('No valid productIDsArray found');
      }
    }

    dispatch(fetchFeaturedItems()).then(response => {
      setFeaturedItems(response);
    });
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHomepage(window.location.href);
    };
  }, [])

  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);
  const isHomePage =
    homepage === 'https://minichr.com' ||
    sections.some(section => section.sectionType === 'hero');

  const layoutAreas = `
    topbar
    hero
    main
    footer
  `;

  const categorySection = {
    sectionType: 'category',
    sectionId: 'category',
    title: 'SHOP BY CATEGORY',
    categories: [
      {
        name: 'MANGA',
        url: '/s?pub_categoryLevel1=Manga-Books&pub_categoryLevel2=manga',
        image: manga
      },
      {
        name: 'FIGURINE',
        url: '/s?pub_categoryLevel1=Figurines-Plushies&pub_categoryLevel2=figurines',
        image: figurine,
      },
      {
        name: 'HANDMADE',
        url: '/s?pub_categoryLevel1=Handcrafted-Goods',
        image: handcrafted,
      },
      {
        name: 'APPAREL',
        url: '/s?pub_categoryLevel1=Apparel',
        image: apparel,
      },
      {
        name: 'ART',
        url: '/s?pub_categoryLevel1=Art',
        image: art,
      },
      {
        name: 'ACCESSORY',
        url: '/s?pub_categoryLevel1=Accessories',
        image: accessories,
      },
    ],
  };
  const profilesSection = {
    sectionType: 'profiles',
    sectionId: 'discover-creators',
    title: 'Discover Independent Creators',
    profiles: profileData
  };

  const swiperSection = {
    sectionType: 'swiper',
    sectionId: 'swiper',
    title: 'RECENTLY VIEWED',
    data: localListings,
    showAuthorInfo: true,
  };

  const featureProductsSwiper = {
    sectionType: 'swiper',
    sectionId: 'swiper',
    title: 'FEATURED PRODUCTS',
    data: featuredItems,
    showAuthorInfo: true,
  };

  const modifiedSections = isHomePage
    ? [categorySection, ...(localListings.length >= 5 ? [swiperSection] : []), featureProductsSwiper, profilesSection]
    : sections;
  
  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {layoutProps => {
          const { Topbar, Main, Footer } = layoutProps;
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer currentPage={currentPage} />
              </Topbar>
              <Main as="main" className={css.main}>
                {modifiedSections.length === 0 && inProgress ? (
                  <LoadingSpinner />
                ) : (
                  <SectionBuilder
                    sections={modifiedSections}
                    options={options}
                  />
                )}
              </Main>
              <Footer>
                <FooterContainer />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, StaticPage, SectionBuilder };
export default PageBuilder;
