import React from 'react';
import styles from './SectionProfile.module.css';
import Information from './Information.png';
import Community from './Community.png';
import Under30 from './Under30.png';
import StaffPick from './StaffPick.jpg';
import NewlyListed from './NewlyListed.avif';
import { AvatarSmedium, AvatarSmall, NamedLink, PrimaryButton } from '../../../../components';


const SectionProfile = ({ sectionId, title, profiles = [], options }) => {

  const cards = [
    {
      title: "TRENDING",
      imageUrl: StaffPick,
      link: "/s?sort=relevance"
    },
    {
      title: "UNDER $30",
      imageUrl: Under30, // Replace with your import or path
      link: "/s?price=0%2C30"
    },
    {
      title: "NEWLY LISTED",
      imageUrl: NewlyListed, // Replace with your import or path
      link: "/s?sort=createdAt"
    }
  ];

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <h4 className={styles.independentCreatorTitleMobile}>DISCOVER CREATORS</h4>
      <div id={sectionId} className={styles.profileSection} options={options}>
        <div className={styles.headerRow}>
          <h4 className={styles.independentCreatorTitle}>DISCOVER</h4>
          <h4 className={styles.independentCreatorTitle}>INDEPENDENT</h4>
          <h4 className={styles.independentCreatorTitle}>CREATORS</h4>
          <a href="/s?pub_categoryLevel1=Handcrafted-Goods">
            <button className={styles.ctaButtonWhite}>See All</button>
          </a>
        </div>
        <div className={styles.profileCardsRow}>
          {profiles.map(profile => (
            <div key={profile.username} className={styles.profileCard}>
              <div className={styles.profileCardImageContainer}>
                <NamedLink
                  title={profile.attributes.profile.displayName}
                  name="ProfilePage"
                  params={{ id: profile.id.uuid }}
                >
                  <img
                    src={profile.productImage}
                    alt={`Profile of ${profile.username}`}
                    className={styles.profileImage}
                  />
                </NamedLink>
              </div>
              <div className={styles.author}>
                <AvatarSmedium user={profile} className={styles.mediumAvatar} />
                <AvatarSmall user={profile} className={styles.smallAvatar} />
                <span className={styles.providerNameLinked}>
                  <NamedLink
                    title={profile.attributes.profile.displayName}
                    name="ProfilePage"
                    params={{ id: profile.id.uuid }}
                  >
                    <span className="username">{profile.attributes.profile.displayName}</span>
                  </NamedLink>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.communitySection}>
        <img src={Information} alt="information" className={styles.communityImage} />
      </div>
      <h4 className={styles.curatedTitle}>CURATED COLLECTIONS</h4>
      <div className={styles.curatedSection}>
        <div className={styles.cardRow}>
          {cards.map((card, index) => (
            <div
              key={index}
              className={styles.card}
              onClick={() => (window.location.href = card.link)}
            >
              <div className={styles.cardImageContainer}>
                <img src={card.imageUrl} alt={card.title} className={styles.cardImage} />
              </div>
              <div className={styles.cardContent}>
                <span>{card.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.communityEmail}>
          <img src={Community} alt="community" className={styles.communityImage} />
          <div className={styles.inputWrapper}>
            <input type="text" className={styles.inputWithChevron} placeholder="Your Email" />
          </div>
        </div>
    </div>
  );
};

export default SectionProfile;
